import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt, FaArrowDown, FaMapMarkerAlt, FaCheck } from 'react-icons/fa';

function Contact() {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          service_id: 'your_service_id',
          template_id: 'your_template_id',
          user_id: 'your_user_id',
          template_params: formData,
        }),
      });

      if (response.ok) {
        setSubmitted(true);
        setTimeout(() => setSubmitted(false), 3000);
        setFormData({ name: '', email: '', message: '' }); // Clear form on success
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const ContactCard = ({ icon, title, description, link, linkText }) => (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
      <div className="text-[#397D5A] text-2xl mx-auto mb-4">{icon}</div>
      <h3 className="font-semibold mb-2">{title}</h3>
      <p className="mb-2">{description}</p>
      {link && (
        <a href={link} className="text-[#397D5A] hover:text-[#1B5538] transition-colors">
          {linkText}
        </a>
      )}
    </div>
  );

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative text-center py-16 bg-gradient-to-b from-gray-100 to-white">
        <div className="absolute inset-0 bg-[#1B5538] opacity-5 pattern-grid-lg"></div>
        <div className="relative z-10">
          <img
            src="/images/KgosanaKoketso.jpeg"
            alt="Kgosana Koketso Rakhudu"
            loading="lazy"
            className="mx-auto w-40 h-40 rounded-full mb-4 shadow-lg border-4 border-white"
          />
          <h1 className="text-4xl font-bold mb-2 text-[#1B5538]">Contact the Office of Kgosana Koketso Rakhudu</h1>
          <p className="text-lg text-gray-700 mb-4">"We're here to listen and support our community."</p>
          <FaArrowDown className="animate-bounce text-[#397D5A] text-2xl mx-auto mt-4 cursor-pointer" />
        </div>
      </section>

      {/* Contact Information */}
      <div className="container mx-auto px-6 py-12">
        <section className="text-center mt-8">
          <h2 className="text-2xl font-semibold mb-8 text-[#1B5538]">Get in Touch</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-4xl mx-auto">
            <ContactCard
              icon={<FaEnvelope />}
              title="Email"
              link="mailto:executivesupport@koketsorakhudu.com"
              linkText="dumela@drkoketsorakhudu.com"
            />
            <ContactCard
              icon={<FaPhoneAlt />}
              title="Primary Phone"
              link="tel:+27636445723"
              linkText="+27 63 644 5723"
            />
            <ContactCard
              icon={<FaPhoneAlt />}
              title="Secondary Phone"
              link="tel:+27829481438"
              linkText="+27 82 948 1438"
            />
            <ContactCard
              icon={<FaEnvelope />}
              title="Calvin Molokwane"
              description="Executive Head of Programmes and Stakeholder Engagement"
              link="mailto:calvin@koketsorakhudu.com"
              linkText="calvin@koketsorakhudu.com"
            />
            <ContactCard
              icon={<FaEnvelope />}
              title="Hlolelo Rampete"
              description="Executive Operations Portfolio: Operations, Programmes & Strategic Planning"
              link="mailto:hlolelo@drkoketsorakhudu.com"
              linkText="hlolelo@drkoketsorakhudu.com"
            />          
            <ContactCard
              icon={<FaEnvelope />}
              title="Sibusiso Nxumalo"
              description="Executive Investment Portfolio: Data Science, Research & Partnerships"
              link="mailto:sibusiso@drkoketsorakhudu.com"
              linkText="sibusiso@drkoketsorakhudu.com"
            />
          </div>


          
        </section>

        {/* Contact Form */}
        <section className="max-w-xl mx-auto bg-white p-8 rounded-lg shadow-lg mt-16 relative">
          {submitted && (
            <div className="absolute inset-0 bg-white bg-opacity-90 flex items-center justify-center z-10">
              <div className="text-center text-[#1B5538]">
                <FaCheck className="text-4xl mb-2 mx-auto" />
                <p className="font-semibold">Message Sent Successfully!</p>
              </div>
            </div>
          )}
          <h2 className="text-2xl font-semibold text-[#1B5538] mb-6">Send Us a Message</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                Your Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#1B5538]"
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                Your Email
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#1B5538]"
                required
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                Message
              </label>
              <textarea
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#1B5538]"
                required
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-[#397D5A] text-white font-bold rounded-md hover:bg-[#1B5538] transition duration-200"
            >
              Send Message
            </button>
          </form>
        </section>
      </div>
    </div>
  );
}

export default Contact;
